import React, { createContext, useState, useEffect, useCallback } from 'react';
import { isFromLastWeek } from '../utils/dateUtils';

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const initializeState = (key, filterFn = () => true) => {
    const stored = localStorage.getItem(key);
    const parsed = stored ? JSON.parse(stored) : [];
    return parsed.filter((item) => item.isFavorite).filter(filterFn);
  };

  const [favorites, setFavorites] = useState(() =>
    initializeState(
      'favorites',
      (favorite) => !isFromLastWeek(favorite.dateAdded)
    )
  );
  const [allTimeFavorites, setAllTimeFavorites] = useState(() =>
    initializeState('allTimeFavorites')
  );

  const updateLocalStorage = useCallback(() => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
    localStorage.setItem('allTimeFavorites', JSON.stringify(allTimeFavorites));
  }, [favorites, allTimeFavorites]);

  useEffect(() => {
    updateLocalStorage();
  }, [updateLocalStorage]);

  const toggleFavorite = useCallback((product) => {
    const productWithMeta = {
      ...product,
      isFavorite: true,
      dateAdded: new Date().toISOString(),
    };

    setFavorites((prev) => {
      const exists = prev.some((fav) => fav._id === product._id);
      return exists
        ? prev.filter((fav) => fav._id !== product._id)
        : [...prev, productWithMeta];
    });

    setAllTimeFavorites((prev) => {
      const exists = prev.some((fav) => fav._id === product._id);
      return exists
        ? prev.filter((fav) => fav._id !== product._id)
        : [...prev, productWithMeta];
    });
  }, []);

  const isFavorite = useCallback(
    (productId) =>
      [...favorites, ...allTimeFavorites].some((fav) => fav._id === productId),
    [favorites, allTimeFavorites]
  );

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        allTimeFavorites,
        toggleFavorite,
        isFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
